'use strict'
const _ = require('lodash')
const immutable = require('immutable')

const wixImmutable = Object.create(immutable)

//Shahar Zur: Facebook won't resolve this bug - https://github.com/facebook/immutable-js/pull/452, so we wrote our own fromJS
wixImmutable.fromJS = function fromJSBecauseFacebookAreStupid(obj) {
    if (_.isArray(obj)) {
        return immutable.List(_.map(obj, fromJSBecauseFacebookAreStupid))
    }
    if (_.isObject(obj)) {
        return immutable.Map(_.mapValues(obj, fromJSBecauseFacebookAreStupid))
    }
    return obj
}

module.exports = wixImmutable
