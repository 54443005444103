(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("immutable"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash", "immutable"], factory);
	else if(typeof exports === 'object')
		exports["wixImmutable"] = factory(require("lodash"), require("immutable"));
	else
		root["wixImmutable"] = factory(root["lodash"], root["immutable"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__134__) {
return 